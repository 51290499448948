import Cookies from "js-cookie";

export function trackConversion(
  userEmail,
  currencyType,
  companyName,
  enhancedEcommerceOrdersTrackingData,
  useHackyTimer
) {
  const promises = [];
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    user_data: {
      email: userEmail,
    },
  });

  enhancedEcommerceOrdersTrackingData.forEach((orderTrackingData) => {
    if (!Cookies.get(orderTrackingData.shortReference)) {
      const isNewCustomer = orderTrackingData.firstOrderForUser;

      window.dataLayer.push({
        dimension6: isNewCustomer ? "true" : "false",
      });

      promises.push(
        new Promise((resolve, reject) => {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: "purchase",
            ecommerce: {
              transaction_id: orderTrackingData.reference,
              value: orderTrackingData.productTotal,
              profit: orderTrackingData.profit,
              currency: currencyType,
              tax: orderTrackingData.tax,
              shipping: orderTrackingData.deliveryPrice,
              coupon: orderTrackingData.discountCode || "",
              subscription: orderTrackingData.subscription,
              newCustomer: isNewCustomer,
              items: [
                {
                  item_id: orderTrackingData.product.code,
                  item_name: orderTrackingData.product.name,
                  item_category: orderTrackingData.product.category,
                  affiliation: companyName,
                  quantity: orderTrackingData.quantity,
                  price: orderTrackingData.productTotal / orderTrackingData.quantity,
                },
              ],
            },
          });
          resolve();
        })
      );

      if (isNewCustomer) {
        promises.push(
          new Promise((resolve, reject) => {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: "new_customer_ltv",
              main_item_identifier: orderTrackingData.itemIdentifier,
              ecommerce: {
                transaction_id: orderTrackingData.reference,
                currency: currencyType,
                subscription: orderTrackingData.subscription,
                items: [
                  {
                    item_id: orderTrackingData.product.code,
                    item_name: orderTrackingData.product.name,
                    item_category: orderTrackingData.product.category,
                    affiliation: companyName,
                    quantity: orderTrackingData.quantity,
                    price: orderTrackingData.productTotal / orderTrackingData.quantity,
                  },
                ],
              },
            });
            resolve();
          })
        );
      }

      Cookies.set(orderTrackingData.shortReference, true);
    }
  });

  if (useHackyTimer) {
    // Without this hack, some events (namely new_customer_ltv) got cancelled
    // before the requests could complete, despite having been wrapped in promises.
    promises.push(
      new Promise((resolve, reject) => {
        setTimeout(resolve, 1000);
      })
    );
  }

  return Promise.all(promises);
}

window.ConversionTracking = { trackConversion };
